import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import {
  HeaderStyle,
  CopyStyle,
  LinkButton,
  HeaderXLStyle,
  CopyLGStyle,
} from '../../components/UIHospitality'
import Layout from '../../components/LayoutHospitality'
import Featured from '../../components/Featured'
import Clients from '../../components/Clients'
import FrequentlyAskedQuestions from '../../components/FrequentlyAskedQuestions'
import CallToAction from '../../components/CallToAction'
import Seo from '../../components/Seo'

const Homepage = ({ location, data }) => {
  const pageData = data.contentfulHomepage

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metadataTitle || 'Home'}
        description={pageData.metadataDescription}
        image={
          pageData.metadataImage
            ? `https:${pageData.metadataImage.file.url}`
            : undefined
        }
      />
      {/* Hero */}
      <section tw="text-[#EFEFEF] bg-[#202C3A] overflow-hidden lg:(h-screen -mt-20 pt-20)">
        <div tw="flex flex-col lg:(grid grid-cols-[1fr repeat(12, calc(1200px/12)) 1fr] relative min-h-[calc(100vh - 80px)])">
          {/* Content */}
          <div tw="m-7 lg:(m-0 col-start-2 col-span-6) flex flex-col justify-center">
            <h1
              css={[
                HeaderXLStyle,
                tw`text-[white] mb-[20px] text-[30px] !line-height[1.2] font-extrabold lg:(text-[48px] mb-[20px])`,
              ]}
            >
              {pageData.heroHeadline}
            </h1>
            <p css={[CopyLGStyle, tw`text-[white]`, tw`text-[18px]`]}>{pageData.heroSubhead}</p>

            <div css={[CopyStyle, tw`flex flex-wrap mb-0 mt-[52px] lg:(mb-[22px])`]}>
              <a href='/hospitality/find-talent' tw="px-[32px] py-[14px] bg-[#FF4D00] text-white mr-0 mb-2 text-center font-bold w-full lg:(w-auto mr-[28px] mb-0)">
                I'm a Facility
              </a>

              <a href="/hospitality/start-earning" tw="px-[32px] py-[14px] bg-[#1f2c3a] text-white mr-0 mb-2 text-center font-bold w-full lg:(w-auto mr-[28px] mb-0)">
                I'm a Professional
              </a>
            </div>

          </div>
          {/* Image */}
          <div tw="relative lg:(col-start-7 col-span-9 absolute h-full)">
            <GatsbyImage
              objectFit="contain"
              objectPosition={'left bottom'}
              tw="w-full h-full z-[1]"
              image={data.hand.childImageSharp.gatsbyImageData}
              alt={'Staffy Banner Image'}
              loading="eager"
            />
          </div>
        </div>
      </section>

      <Featured data={pageData.featuredIn} />

      {/* Employers and Workers */}
      <section tw="bg-[#E9F1F3] lg:(pt-10 pb-20)">
        <div tw="flex flex-col lg:(py-0 grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative h-full max-w-screen-xl mx-auto)">
          {/* Image */}
          <div tw="lg:(col-span-6)">
            <GatsbyImage
              tw="w-full h-full object-cover"
              image={pageData.section2Image.gatsbyImageData}
              alt={pageData.section2Image.filename}
            />
          </div>
          {/* Copy */}
          <div tw="mx-7 py-14 lg:(mx-0 py-0 col-start-8 col-span-5 flex flex-col justify-center)">
            {/* Workers */}
            <div tw="mb-12 lg:mb-[72px]">
              <h2 css={[HeaderStyle, tw`mb-6`]}>
                {pageData.section2Headline2}
              </h2>
              <p css={[CopyStyle, tw`mb-5`]}>
                {pageData.section2Subhead2.section2Subhead2}
              </p>
              <LinkButton destination="/hospitality/start-earning">
                {pageData.section2Cta2}
              </LinkButton>
            </div>

            <hr tw="border-[2px] border-[#FF4D00]" />

            {/* Employers */}
            <div tw="mt-12 lg:mt-[72px]">
              <h2 css={[HeaderStyle, tw`mb-6`]}>
                {pageData.section2Headline1}
              </h2>
              <p css={[CopyStyle, tw`mb-5`]}>
                {pageData.section2Subhead1.section2Subhead1}
              </p>
              <LinkButton destination="/hospitality/find-talent#book-a-demo">
                {pageData.section2Cta1}
              </LinkButton>
            </div>
          </div>
        </div>
      </section>

      {/* Clients */}
      <Clients clients={pageData.clients} hospitality={true} />

      {/* What makes us different */}
      {pageData.section3StaffyFeatures && (
        <section tw="max-w-screen-xl mx-auto">
          <div tw="flex flex-col lg:(py-16 grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative h-full items-center)">
            {/* Headline */}
            <div tw="order-1 mx-7 mt-16 mb-8 lg:(order-1 mx-0 mt-0 mb-0 col-start-2 col-span-12 text-center)">
              <h2 css={[HeaderStyle]}>{pageData.section3Headline}</h2>
            </div>
            {/* Features */}
            <div tw="order-3 mx-7 py-16 lg:(order-2 mx-0 col-start-2 col-span-5 flex flex-col justify-center)">
              {pageData.section3StaffyFeatures.map((feature, index) => (
                <div tw="mb-12" key={`staff-feature-${index}`}>
                  <GatsbyImage
                    tw="mb-4 w-10 lg:mb-2"
                    image={feature.icon.gatsbyImageData}
                    alt={feature.icon.filename}
                  />
                  <h3 tw="text-[#FF4D00] font-bold text-3xl mb-3.5 lg:(mb-5 text-5xl)">
                    {feature.headline}
                    <span tw="block text-2xl font-semibold">
                      {feature.subhead}
                    </span>
                  </h3>
                  <p css={[CopyStyle]}>{feature.description.description}</p>
                </div>
              ))}
              <div tw="lg:w-[180px]">
                <LinkButton destination="/hospitality/find-talent#book-a-demo">
                  {pageData.section2Cta1}
                </LinkButton>
              </div>
            </div>
            {/* Image */}
            <div tw="order-2 lg:(order-3 col-start-8 col-span-7)">
              <GatsbyImage
                tw="w-full"
                image={pageData.section3Image.gatsbyImageData}
                alt={pageData.section3Image.filename}
              />
            </div>
          </div>
        </section>
      )}

      <FrequentlyAskedQuestions
        headline={pageData.faqHeadline}
        subhead={pageData.faqCopy.faqCopy}
        questions={pageData.faqQuestions}
        image={pageData.faqImage}
        hospitalityRoute={true}
      />

      <CallToAction
        header={pageData.ctaHeadline}
        copy={pageData.ctaCopy.ctaCopy}
        ctas={[
          { copy: 'Start Earning', destination: '/hospitality/start-earning' },
          { copy: 'Find Talent', destination: '/hospitality/find-talent' },
        ]}
        image={pageData.ctaImage}
      />
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  {
    contentfulHomepage(
      pageId: {
        eq: "Homepage" 
      },
      spaceId: {
        eq: "ufz0zh9i8v45"
      }
      ) {
      heroHeadline
      heroSubhead
      featuredIn {
        name
        logo {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 200)
        }
      }
      section2Image {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1024
          quality: 80
        )
      }
      section2Headline1
      section2Subhead1 {
        section2Subhead1
      }
      section2Cta1
      section2Headline2
      section2Subhead2 {
        section2Subhead2
      }
      section2Cta2
      clients {
        name
        logo {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 300
            quality: 100
          )
        }
      }
      section3Headline
      section3StaffyFeatures {
        icon {
          filename
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 80
            quality: 100
          )
        }
        headline
        subhead
        description {
          description
        }
      }
      section3Image {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1024
          quality: 80
        )
      }
      faqImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1024
          quality: 80
        )
      }
      faqHeadline
      faqCopy {
        faqCopy
      }
      faqQuestions {
        question
        answer {
          raw
        }
      }
      ctaHeadline
      ctaCopy {
        ctaCopy
      }
      ctaImage {
        filename
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1024
          quality: 80
        )
      }
      metadataTitle
      metadataDescription
      metadataImage {
        file {
          url
        }
      }
    }
    hand: file(relativePath: { eq: "homepage-hand.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1024
          placeholder: NONE
          quality: 80
          transformOptions: { cropFocus: SOUTHWEST, fit: CONTAIN }
        )
      }
    }
  }
`
